import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { useHandleError } from './logErrors';
import { envs } from '../env';
import { z } from 'zod';
import posthog from 'posthog-js';
import { UserDTO } from '@/services/userDataService';

export type RemoteConfigObject = z.infer<typeof remoteConfigSchema>;
export type RemoteConfigKey = keyof RemoteConfigObject;

const config = {
  staging: {
    firebaseConfig: {
      apiKey: 'AIzaSyAaft83ZYHuGDeWh9EgGiAyE4kJAfYk4FU',
      authDomain: 'saga-staging-5fd25.firebaseapp.com',
      projectId: 'saga-staging-5fd25',
      storageBucket: 'saga-staging-5fd25.appspot.com',
      messagingSenderId: '456740563977',
      appId: '1:456740563977:web:1c52a90e0386a0f48b1cd8',
      measurementId: 'G-ZKRRTVQ1WB',
    },
    minimumFetchIntervalMillis: 1000,
  },
  master: {
    firebaseConfig: {
      apiKey: 'AIzaSyAVLir5AYmrJps_Y2sOFw7x4RO94o_p72g',
      authDomain: 'saga-master.firebaseapp.com',
      projectId: 'saga-master',
      storageBucket: 'saga-master.appspot.com',
      messagingSenderId: '149651740177',
      appId: '1:149651740177:web:6e0bf2f074a0d6a0bd83b0',
      measurementId: 'G-S9R8DTQZTQ',
    },
    minimumFetchIntervalMillis: 1000 * 60 * 60 * 12,
  },
};

const remoteConfigSchema = z.object({
  feature_broadcast_enabled: z.boolean().default(false),
  tester_user_ids: z.array(z.number()).default([]),
  saga_link_expire_date: z.string().default('2024-03-22T14:42:10.304Z'),
});

export function useFirebase() {
  const initApp = () => {
    try {
      return initializeApp(config[envs.VITE_FIREBASE_ENV].firebaseConfig);
    } catch (e) {
      throwError(e);
    }
  };

  const app = initApp();
  const remoteConfig = getRemoteConfig(app);
  const parsedRemoteConfig = remoteConfigSchema.parse(remoteConfig);
  const analytics = getAnalytics(app);
  const { throwError } = useHandleError();

  const initRemoteConfig = () => {
    try {
      remoteConfig.settings.minimumFetchIntervalMillis = config[envs.VITE_FIREBASE_ENV].minimumFetchIntervalMillis;
      remoteConfig.defaultConfig = {
        ...parsedRemoteConfig,
        tester_user_ids: JSON.stringify(parsedRemoteConfig.tester_user_ids),
      };
    } catch (e) {
      throwError(e);
    }
  };

  const setUserIdOnAnalytic = (user: UserDTO) => {
    setUserId(analytics, user.id.toString());
    // TODO this will need to be refactored into a posthog file
    posthog.identify(user.email, {
      fullName: `${user.firstName} ${user.lastName}`,
      tenantName: user.tenant.tenant,
      language: user.accountLanguage || 'unknown',
      email: user.email,
    });
  };

  const syncRemoteConfig = async () => {
    if (window.sessionStorage.getItem('isTesting') === 'true') {
      console.info('SKIPPING firebase because TESTING');
      return;
    }
    try {
      await fetchAndActivate(remoteConfig);
    } catch (error) {
      throwError(error);
    }
  };

  const getRemoteConfigValue = <D extends keyof RemoteConfigObject>(key: D): RemoteConfigObject[D] => {
    const value = getValue(remoteConfig, key);
    let remoteValue;
    switch (typeof parsedRemoteConfig[key]) {
      case 'boolean':
        remoteValue = value.asBoolean();
        break;
      case 'number':
        remoteValue = value.asNumber();
        break;
      case 'string':
        remoteValue = value.asString();
        break;
      default:
        remoteValue = JSON.parse(value.asString());
        break;
    }
    const parsedValue = remoteConfigSchema.shape[key].safeParse(remoteValue);
    if (parsedValue.success) return parsedValue.data as RemoteConfigObject[D];

    throwError(new Error(`Invalid remote config value for key ${key} received: ${remoteValue}`));
    return remoteConfigSchema.shape[key].parse(remoteConfig.defaultConfig[key]) as RemoteConfigObject[D];
  };

  return {
    initRemoteConfig,
    setUserIdOnAnalytic,
    syncRemoteConfig,
    getRemoteConfigValue,
  };
}
