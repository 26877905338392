import { ref } from 'vue';
import http, { useAxiosUtils } from './axiosClient';
import { loadStripe, Stripe, StripeElement } from '@stripe/stripe-js';
import { Tenant, useTenantService } from './tenantService';
import { useHandleError } from './logErrors';
import { useUtilsService } from './utilsService';
import { useUserStore } from '@/stores/userStore';
import { envs } from '@/env';

export type RecurringInterval = 'day' | 'week' | 'month' | 'year';

export type Plan = {
  freeTrialDays: number;
  prices: Price[];
};

export type Price = {
  priceId: string;
  price: number;
  currency: string;
  recurring: {
    interval: RecurringInterval;
    intervalCount: number;
  };
};

export type SubscriptionsStatus =
  | 'no_subscription'
  | 'trialing'
  | 'trial_expired'
  | 'subscribing'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'expired';

export type SubscriptionsStatusDTO = {
  isValid: boolean;
  status: SubscriptionsStatus;
  freeTrialMillisecondsLeft: number;
  features: string[];
};

export type CheckoutSessionStatus = 'open' | 'expired' | 'complete';

export type CheckoutSessionStatusDTO = {
  status: CheckoutSessionStatus;
  customerId: string;
};

const plans = ref<Partial<Record<Tenant, Plan>>>({});
let stripe: Stripe | null = null;

export type CustomerPortalFlowDataType = 'payment_method_update' | 'subscription_update_confirm' | 'subscription_update';

export function useStripeService() {
  const { getHeaders } = useAxiosUtils();
  const { getTenantName } = useTenantService();
  const { throwError } = useHandleError();
  const { getCurrencyFromTimeZone, retryWithExponentialBackoff } = useUtilsService();
  const userStore = useUserStore();

  const getStripe = async () => {
    if (stripe) {
      return stripe;
    }
    const script = document.createElement('script');
    script.id = 'stripe-js';
    script.src = 'https://js.stripe.com/v3/';
    script.onerror = function handleScriptError() {
      throwError('Failed to load stripe - script.onerror');
      throw new Error('Failed to load stripe - script.onerror');
    };

    document.head.appendChild(script);
    const loadedStripe = await loadStripe(envs.VITE_STRIPE_PUBLISHABLE_KEY);
    if (!loadedStripe) {
      throw new Error('Failed to load stripe - loadStripe');
    }
    stripe = loadedStripe;
    return loadedStripe;
  };

  const getPlanByTenant = async () => {
    const currency = getCurrencyFromTimeZone();
    const tenantName = getTenantName();
    if (!tenantName) {
      const error = new Error('Tenant not found');
      throwError(error);
      throw error;
    }
    const plan = plans.value[tenantName];
    if (plan) return plan;

    const res = await http.get<Plan>(`/api/stripe/${tenantName}/plans?currency=${currency}`);
    plans.value[tenantName] = res.data;
    return res.data;
  };

  const createCheckoutSession = async (priceId: string, redirectPath: string) => {
    const currency = getCurrencyFromTimeZone();
    const res = await http.post<{ id: string; clientSecret: string }>(
      `/api/stripe/checkout-session`,
      { priceId, redirectPath, currency },
      getHeaders()
    );
    return res.data;
  };

  const getSessionStatus = async (sessionId: string) => {
    const res = await http.get<CheckoutSessionStatusDTO>(`/api/stripe/checkout-session/${sessionId}`, getHeaders());
    return res.data;
  };

  const fulfillCheckout = async (checkoutSessionId: string) => {
    await http.post<void>(`/api/stripe/fulfill-checkout`, { checkoutSessionId }, getHeaders());
  };

  const getSubscriptionStatus = async () => {
    const res = await http.get<SubscriptionsStatusDTO>(`/api/stripe/subscription-status`, getHeaders());
    return res.data;
  };

  const createCustomerPortalSession = async (flowDataType?: CustomerPortalFlowDataType, returnUrlPath?: string) => {
    const res = await http.post<{ url: string }>(
      `/api/stripe/customer-portal`,
      {
        flowDataType,
        returnUrlPath,
      },
      getHeaders()
    );
    return res.data;
  };

  async function handleLoggedInUserSubscription(withRetry?: boolean): Promise<void> {
    let responseSubscriptionStatus: SubscriptionsStatusDTO | null = null;
    if (withRetry) {
      responseSubscriptionStatus = await retryWithExponentialBackoff(
        useStripeService().getSubscriptionStatus,
        3,
        1000,
        (result: SubscriptionsStatusDTO) => result.isValid === true
      );
    } else {
      responseSubscriptionStatus = await useStripeService().getSubscriptionStatus();
    }
    userStore.updateSubscriptionStatus({
      isUserSubscribed: responseSubscriptionStatus.isValid,
      freeTrialDaysLeft: Math.round(responseSubscriptionStatus.freeTrialMillisecondsLeft / (1000 * 60 * 60 * 24)),
      status: responseSubscriptionStatus.status,
    });
  }

  return {
    getStripe,
    getPlanByTenant,
    getSessionStatus,
    fulfillCheckout,
    getSubscriptionStatus,
    createCustomerPortalSession,
    createCheckoutSession,
    handleLoggedInUserSubscription,
  };
}
