import { LeadInterest, LeadMembership, SourceOptions } from '@/services/leadDataService';
import { MenuItems } from '@/components/MenuComponent.vue';

export const menuItemsToHide: MenuItems[] = [];

export const sourcesToShow: SourceOptions[] = [
  'CSV',
  'Manually Created',
  'CONTACT_ME_FORM',
  'INFORMATION_FLOW_FORM',
  'INFORMATION_FLOW',
  'LEAD_MAGNET',
];
export const membershipsToShow: LeadMembership[] = ['Lead', 'User', 'Customer', 'Affiliate', 'Smartship', 'Promoter', 'Lost'];

export const interestsToShow: LeadInterest[] = ['business', 'product'];
